import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import classes from "./AccountInfoTitleRow.module.scss";
import "./AccountInfoTitleRow.css";

import Search from "../../../assets/img/search.svg";
import VerticalGrayLineSvg from "../../../assets/img/verticalGrayLine.svg";
import AddFilterButtonIconSvg from "../../../assets/img/addFilterButtonIcon.svg";
import ImportIcon from "../../../assets/img/import.svg";

import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../custom-hooks/useDebounce";
import StringItemsFilter from "./filters/StringItemsFilter";
import RangeFilter from "./filters/RangeFilter";
import NumOfRecordsSelector from "./filters/NumOfRecordsSelector";
import OrderBySelector from "./filters/OrderBySelector";
import {
  IImportListInfo,
  IRangeFieldInfo,
  IStringItemsFieldInfo,
  IOrderBy,
  UPLOAD_STATES,
} from "../../../services/accountInterfaces";
import ImportFileFilter from "./filters/ImportFileFilter";

export interface IAccountInfoTitleRowProps {
  hasAccounts: boolean;
  filteredAccountCount: number;
  onReloadAccounts: () => void;
  onExportAccounts: () => void;

  initialSearchTerm?: string;
  onSearchTermChange: (val: string) => void;

  scoreRangeFilterInfo: IRangeFieldInfo;
  onScoreRangeFilterChange: (val: IRangeFieldInfo) => void;

  durationRangeFilterInfo: IRangeFieldInfo;
  onDurationRangeFilterChange: (val: IRangeFieldInfo) => void;

  householdsRangeFilterInfo: IRangeFieldInfo;
  onHouseholdsRangeFilterChange: (val: IRangeFieldInfo) => void;

  labelItemsFilterInfo: IStringItemsFieldInfo;
  onLabelItemsFilterChange: (val: IStringItemsFieldInfo) => void;

  tagItemsFilterInfo: IStringItemsFieldInfo;
  onTagItemsFilterChange: (val: IStringItemsFieldInfo) => void;

  importListFilterInfo: IImportListInfo;
  onImportListFilterChange: (val: IImportListInfo) => void;

  selectedRecordCount: number;
  queryRecordCount: number;
  onAccountsRecordCountChange: (recordCount: number) => void;

  selectedOrderBy: IOrderBy;
  queryOrderBy: IOrderBy;
  onOrderByChange: (val: IOrderBy) => void;

  view: string;
}

const AccountInfoTitleRow = (props: IAccountInfoTitleRowProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const inputFile = useRef(null);

  useEffect(() => {
    // Currently initialSearchTerm is always ""
    setSearchTerm(props.initialSearchTerm);
  }, [props.initialSearchTerm]);

  const DEBOUNCE_INPUT_MILLIS = 500;
  const debouncedSearchTerm: string = useDebounce(
    searchTerm,
    DEBOUNCE_INPUT_MILLIS
  );

  const { onSearchTermChange: propsOnSearchTermChange } = props;

  useEffect(() => {
    propsOnSearchTermChange(debouncedSearchTerm);
  }, [debouncedSearchTerm, propsOnSearchTermChange]);

  const handleAddFilterClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuWidth(event.currentTarget?.clientWidth || 0);
  };
  const handleAddFilterMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleScoreMenuItemClick = () => {
    props.onScoreRangeFilterChange({
      ...props.scoreRangeFilterInfo,
      isDisplayed: !props.scoreRangeFilterInfo.isDisplayed,
    });
    handleAddFilterMenuClose();
  };

  const handleRemoveScoreFilterClick = () => {
    handleScoreMenuItemClick();
  };

  const handleScoreFilterRangeChange = (from: number, to: number) => {
    props.onScoreRangeFilterChange({
      ...props.scoreRangeFilterInfo,
      current: [from, to],
    });
  };

  const handleSearchTermChange = (newVal: string) => {
    setSearchTerm(newVal);
  };

  const handleDurationMenuItemClick = () => {
    props.onDurationRangeFilterChange({
      ...props.durationRangeFilterInfo,
      isDisplayed: !props.durationRangeFilterInfo.isDisplayed,
    });
    handleAddFilterMenuClose();
  };

  const handleRemoveDurationFilterClick = () => {
    handleDurationMenuItemClick();
  };

  const handleDurationFilterRangeChange = (from: number, to: number) => {
    props.onDurationRangeFilterChange({
      ...props.durationRangeFilterInfo,
      current: [from, to],
    });
  };

  const handleHouseholdsMenuItemClick = () => {
    props.onHouseholdsRangeFilterChange({
      ...props.householdsRangeFilterInfo,
      isDisplayed: !props.householdsRangeFilterInfo.isDisplayed,
    });
    handleAddFilterMenuClose();
  };

  const handleRemoveHouseholdsFilterClick = () => {
    handleHouseholdsMenuItemClick();
  };

  const handleHouseholdsFilterRangeChange = (from: number, to: number) => {
    props.onHouseholdsRangeFilterChange({
      ...props.householdsRangeFilterInfo,
      current: [from, to],
    });
  };

  const handleLabelMenuItemClick = () => {
    props.onLabelItemsFilterChange({
      ...props.labelItemsFilterInfo,
      isDisplayed: !props.labelItemsFilterInfo.isDisplayed,
    });
    handleAddFilterMenuClose();
  };

  const handleRemoveLabelFilterClick = () => {
    handleLabelMenuItemClick();
  };

  const handleLabelFilterSelectionChange = (selected: string[]) => {
    props.onLabelItemsFilterChange({
      ...props.labelItemsFilterInfo,
      current: selected,
    });
  };

  const handleRemoveTagsFilterClick = () => {
    handleTagsMenuItemClick();
  };

  const handleTagsMenuItemClick = () => {
    props.onTagItemsFilterChange({
      ...props.tagItemsFilterInfo,
      isDisplayed: !props.tagItemsFilterInfo.isDisplayed,
    });
    handleAddFilterMenuClose();
  };

  const handleTagsFilterSelectionChange = (selected: string[]) => {
    props.onTagItemsFilterChange({
      ...props.tagItemsFilterInfo,
      current: selected,
    });
  };

  const handleRemoveImportListFilterClick = () => {
    props.onImportListFilterChange({
      ...props.importListFilterInfo,
      isDisplayed: false,
      current: null,
    });
    handleAddFilterMenuClose();
  };

  const handleImportListMenuItemClick = () => {
    props.onImportListFilterChange({
      ...props.importListFilterInfo,
      current: null,
      isDisplayed: true,
      uploadeFileState: UPLOAD_STATES.INIT,
    });
    handleAddFilterMenuClose();

    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleAccountListResponse = (importListInfo: IImportListInfo) => {
    props.onImportListFilterChange(importListInfo);
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      props.onImportListFilterChange({
        ...props.importListFilterInfo,
        current: event.target.files[0],
        uploadeFileState: UPLOAD_STATES.UPLOADING,
      });
    } else {
      props.onImportListFilterChange({
        ...props.importListFilterInfo,
        current: null,
      });
    }
  };

  const handleImportListFilterChange = (file: File) => {
    props.onImportListFilterChange({
      ...props.importListFilterInfo,
      current: file,
    });
  };

  const onRecordCountSelectionChange = (recordCount: number) => {
    props.onAccountsRecordCountChange(recordCount);
  };

  return (
    <div>
      <div className={classes.titleRow}>
        <div className={classes.title}>
          All Accounts ({props.filteredAccountCount})
        </div>
        <div>
          <img src={VerticalGrayLineSvg} />
        </div>
        <div className={classes.searchBox}>
          <input
            type="text"
            placeholder="Search..."
            className={classes.search}
            value={searchTerm}
            onChange={(event) => handleSearchTermChange(event.target.value)}
          />
          <button className={classes.searchButton}>
            <img src={Search} alt="sidebar icon" className={classes.logo} />
          </button>
        </div>

        <div style={{ marginLeft: 20 }}>
          <NumOfRecordsSelector
            selectedRecordCount={props.selectedRecordCount}
            queryRecordCount={props.queryRecordCount}
            onSelectionChange={onRecordCountSelectionChange}
          />
        </div>

        <div style={{ marginLeft: 20 }}>
          <OrderBySelector
            selectedOrderBy={props.selectedOrderBy}
            queryOrderBy={props.queryOrderBy}
            onOrderByChange={props.onOrderByChange}
          />
        </div>

        <button
          disabled={props.labelItemsFilterInfo === null}
          style={{ marginLeft: 10, height: 25 }}
          className={classes.titleRowButton}
          onClick={props.onReloadAccounts}
        >
          Submit
        </button>
        {props.view === "table" && (
          <button
            disabled
            onClick={props.onExportAccounts}
            className={classes.titleRowButton}
            style={{ marginLeft: "auto", height: 25 }}
          >
            EXPORT LIST
          </button>
        )}
        {/* <button disabled style={{ marginLeft: 10, height: 25 }}>
          SAVE AS GROUP
        </button> */}
      </div>

      <div className={classes.filterRow}>
        <div className={classes.filters}>
          <Button
            disabled={!props.hasAccounts}
            classes={{ root: classes.titleRowButtonRoot }}
            onClick={handleAddFilterClick}
          >
            <div>
              <img src={AddFilterButtonIconSvg} />
              <span>ADD FILTER</span>
            </div>
          </Button>
          <Menu
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            onClose={handleAddFilterMenuClose}
          >
            <div
              style={{
                width: menuWidth,
                background: "#25293D",
                color: "#F0F0F0",
                border: "1px solid #576079",
              }}
            >
              {props.importListFilterInfo && (
                <MenuItem
                  className={classes.accountInfoImportListFilterMenuItem}
                  onClick={handleImportListMenuItemClick}
                >
                  <div
                    style={{
                      borderBottom: "1px solid #576079",
                      paddingBottom: "0.5rem",
                    }}
                  >
                    <img src={ImportIcon} />
                    <button>IMPORT LIST</button>
                  </div>
                </MenuItem>
              )}
              {props.scoreRangeFilterInfo && (
                <MenuItem
                  classes={{ root: "accountInfoFilterMenuItem" }}
                  onClick={handleScoreMenuItemClick}
                >
                  Score
                </MenuItem>
              )}
              {props.labelItemsFilterInfo && (
                <MenuItem
                  classes={{ root: "accountInfoFilterMenuItem" }}
                  onClick={handleLabelMenuItemClick}
                >
                  Label
                </MenuItem>
              )}
              {props.durationRangeFilterInfo && (
                <MenuItem
                  classes={{ root: "accountInfoFilterMenuItem" }}
                  onClick={handleDurationMenuItemClick}
                >
                  Duration
                </MenuItem>
              )}
              {props.householdsRangeFilterInfo && (
                <MenuItem
                  classes={{ root: "accountInfoFilterMenuItem" }}
                  onClick={handleHouseholdsMenuItemClick}
                >
                  Households
                </MenuItem>
              )}
              {props.tagItemsFilterInfo && (
                <MenuItem
                  classes={{ root: "accountInfoFilterMenuItem" }}
                  onClick={handleTagsMenuItemClick}
                >
                  Tags
                </MenuItem>
              )}
            </div>
          </Menu>

          {props.importListFilterInfo?.isDisplayed && (
            <div className={classes.accountsFilterContainer}>
              <ImportFileFilter
                filterName="Imported List"
                onRemoveFilterClick={handleRemoveImportListFilterClick}
                onFileChange={handleImportListFilterChange}
                onReplaceFile={handleImportListMenuItemClick}
                onAccountListResponse={handleAccountListResponse}
                filterInfo={props.importListFilterInfo}
              />
            </div>
          )}

          {props.scoreRangeFilterInfo?.isDisplayed && (
            <div className={classes.accountsFilterContainer}>
              <RangeFilter
                filterName="Score"
                onRemoveFilterClick={handleRemoveScoreFilterClick}
                onRangeChange={handleScoreFilterRangeChange}
                filterInfo={props.scoreRangeFilterInfo}
              />
            </div>
          )}

          {props.labelItemsFilterInfo?.isDisplayed && (
            <div className={classes.accountsFilterContainer}>
              <StringItemsFilter
                filterName="Label"
                available={props.labelItemsFilterInfo.available}
                selected={props.labelItemsFilterInfo.current}
                isMultiSelect={false}
                onSelectionChange={handleLabelFilterSelectionChange}
                onRemoveFilterClick={handleRemoveLabelFilterClick}
              />
            </div>
          )}

          {props.durationRangeFilterInfo?.isDisplayed && (
            <div className={classes.accountsFilterContainer}>
              <RangeFilter
                filterName="Duration"
                onRemoveFilterClick={handleRemoveDurationFilterClick}
                onRangeChange={handleDurationFilterRangeChange}
                filterInfo={props.durationRangeFilterInfo}
              />
            </div>
          )}

          {props.householdsRangeFilterInfo?.isDisplayed && (
            <div className={classes.accountsFilterContainer}>
              <RangeFilter
                filterName="Households"
                onRemoveFilterClick={handleRemoveHouseholdsFilterClick}
                onRangeChange={handleHouseholdsFilterRangeChange}
                filterInfo={props.householdsRangeFilterInfo}
              />
            </div>
          )}

          {props.tagItemsFilterInfo?.isDisplayed && (
            <div className={classes.accountsFilterContainer}>
              <StringItemsFilter
                filterName="Tags"
                available={props.tagItemsFilterInfo.available}
                selected={props.tagItemsFilterInfo.current}
                isMultiSelect={true}
                onSelectionChange={handleTagsFilterSelectionChange}
                onRemoveFilterClick={handleRemoveTagsFilterClick}
              />
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        id="file"
        accept="text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default AccountInfoTitleRow;
